import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import { MDXRenderer } from 'gatsby-plugin-mdx'
import SEO from 'components/SEO'
import { css } from '@emotion/core'
import Container from 'components/Container'
import Layout from '../components/Layout'
import { fonts } from '../lib/typography'
import config from '../../config/website'
import { bpMaxSM } from '../lib/breakpoints'
import PostList from '../components/PostList'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

export default function Post({
  data: { site, mdx, refersTo, referredBy},
  pageContext: { next, prev },
}) {

  const author = mdx.frontmatter.author || config.author
  const date = mdx.frontmatter.date
  const edited = mdx.frontmatter.edited
  const title = mdx.frontmatter.title
  const banner = mdx.frontmatter.banner
  const status = mdx.frontmatter.status

  return (
    <Layout site={site} frontmatter={mdx.frontmatter}>
      <SEO frontmatter={mdx.frontmatter} isBlogPost />
      <article
        css={css`
          width: 100%;
          display: flex;
          flex-direction: column;
        `}
      >
        <Container>
          <h1
            css={css`
              text-align: center;
              margin-bottom: 20px;
            `}
          >
            {title}
          </h1>
          <div
            css={css`
              display: flex;
              justify-content: center;
              margin-bottom: 20px;
              h3,
              span {
                text-align: center;
                font-size: 15px;
                opacity: 0.6;
                font-family: ${fonts.regular}, sans-serif;
                font-weight: normal;
                margin: 0 5px;
              }
            `}
          >
            <div
              css={css`
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
              `}
            >
              <div
                css={css`
                  text-transform: capitalize;
                  display: flex;
                `}>
                <img
                  src={`/images/${status ? status : 'seedling'}.png`}
                  alt='Post status'
                  height="18px"
                  css={css`
                    margin: 0;
                  `}
                />
                <h3>{status || 'seedling'}</h3>
              </div>
              {date && <h3 css={css`margin= 0 10px`}>planted {date}</h3>}
              {edited && <h3>last tended {edited}</h3>}
            </div>

          </div>
          {banner && (
            <div
              css={css`
                padding: 30px;
                ${bpMaxSM} {
                  padding: 0;
                }
              `}
            >
              <GatsbyImage 
                image={banner.childImageSharp.gatsbyImageData}
                alt={site.siteMetadata.keywords.join(', ')}
              />
            </div>
          )}
          <br />
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </Container>

          <Container css={css`
            display: flex;
            padding: 0;
            ${bpMaxSM} {
              flex-direction: column;
            }
          `}>
            {refersTo && refersTo.edges && refersTo.edges.length > 0 &&
              <Container>
                  <div css={css`
                    display: flex;
                    align-items: center;
                  `}>
                    <FontAwesomeIcon icon={ faExternalLinkAlt } />
                    <span css={css`
                      font-size: 28px;
                      padding-left: 10px;
                      font-family: 'Inter UI Bold';
                    `}>
                      Links in this post:
                    </span>
                  </div>
                <PostList
                  notes={refersTo.edges}
                />
              </Container>
            }

            {referredBy && referredBy.edges && referredBy.edges.length > 0 &&
              <Container>
                  <div css={css`
                    display: flex;
                    align-items: center;
                  `}>
                    <FontAwesomeIcon icon={ faLink } />
                    <span css={css`
                      font-size: 28px;
                      padding-left: 10px;
                      font-family: 'Inter UI Bold';
                    `}>
                    Links to this post:
                  </span>
                </div>
                <PostList notes={referredBy.edges} />
              </Container>
            }
          </Container>

      </article>

    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!, $refersTo: [String], $referredFrom: [String]) {
    site {
      ...site
    }
    mdx(fields: { id: { eq: $id } }) {
      frontmatter {
        title
        date(formatString: "MMM DD, YYYY")
        edited(formatString: "MMM DD, YYYY")
        author
        banner {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        slug
        keywords
        status
      }
      body
    }
    refersTo: allMdx(
      sort: { fields: [fields___mostRecentChange], order: DESC }
      filter: { frontmatter: { slug: { in: $refersTo  } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 120)
          id
          fields {
            title
            slug
            date
            mostRecentChange
          }
          parent {
            ... on File {
              sourceInstanceName
            }
          }
          frontmatter {
            title
            date(formatString: "MMM DD, YYYY")
            edited(formatString: "MMM DD, YYYY")
            description
            slug
            keywords
            status
          }
        }
      }
    }
    referredBy: allMdx(
      sort: { fields: [fields___mostRecentChange], order: DESC }
      filter: { frontmatter: { slug: { in: $referredFrom  } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 120)
          id
          fields {
            title
            slug
            date
            mostRecentChange
          }
          parent {
            ... on File {
              sourceInstanceName
            }
          }
          frontmatter {
            title
            date(formatString: "MMM DD, YYYY")
            edited(formatString: "MMM DD, YYYY")
            description
            slug
            keywords
            status
          }
        }
      }
    }
  }
`
